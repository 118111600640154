/* src/App.css */
* { box-sizing: border-box; margin: 0; padding: 0; }
html, body, #root { height: 100%; font-family: 'Arial', sans-serif; }
.container { display: flex; flex-direction: column; min-height: 100%; }

.btn-contact { border: 2px solid #fff; padding: 10px 25px; border-radius: 50px; background: transparent; cursor: pointer; }

.hero { position: relative; height: 80vh; background-size: cover; background-position: center 20%; display: flex; flex-direction: column; align-items: center; justify-content: center; color: #fff; text-align: center; }
.bg-uploader { position: absolute; top: 20px; right: 40px; opacity: 0.6; }
.hero h1 { font-size: 96px; font-weight: bold; }
.hero p { font-size: 30px; margin-top: 20px; font-weight: bold;}

.highlight {
  color: #375e9f; /* 원하는 색상으로 교체 */
  font-weight: 700; /* (선택) 강조 굵기 */
}


.search-bar { display: flex; align-items: center; margin-top: 30px; background: rgba(255,255,255,0.9); border-radius: 50px; padding: 10px; }
.select-wrapper { position: relative; margin: 0 10px; }
.select-wrapper select { border: none; background: transparent; padding: 10px; font-size: 14px; }
.dropdown-arrow { position: absolute; right: 10px; top: 50%; transform: translateY(-50%); pointer-events: none; }
.btn-find { padding: 12px 30px; border-radius: 50px; border: none; background: #000; color: #fff; cursor: pointer; }

.info { padding: 60px 40px; text-align: center; }
.info p { font-size: 40px; color: #375e9f; font-weight: 700; padding: 40px;}
.info h2 { font-size: 33px; margin-top: 20px; }

.cards { display: grid; grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); gap: 50px; padding: 0 40px 60px; }
.card { background: #fff; border-radius: 12px; overflow: hidden; box-shadow: 0 4px 12px rgba(0,0,0,0.1); }
.card img { width: 100%; height: 180px; object-fit: cover; }
.card-text { padding: 15px; }
.card-text h3 { font-size: 18px; }
.card-text p { font-size: 14px; color: #777; }

.footer { text-align: center; padding: 40px; }
.footer .btn-contact {
  background: #000;
  color: #fff;
  border: none;
  padding: 1rem 2rem;      /* 위아래 16px, 좌우 32px */
  font-size: 1.25rem;      /* 글자 크기 증가 */
  font-weight: bold;
  min-width: 200px;        /* 최소 너비 지정 */
  cursor: pointer;

}

.legal { font-size: 12px; color: #555; text-align: center; padding: 20px 40px; border-top: 1px solid #ddd; line-height: 1.5; }
.legal a { text-decoration: underline; color: inherit; }


.work-section {
    text-align: center;
    padding: 12rem 1rem;
    opacity: 90%;
  }
  
  .work-title {
    font-size: 3rem;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 3rem;
    line-height: 1.3;
  }
  
  .work-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8rem;
  }
  
  .work-card {
    max-width: 320px;
  }
  
  .work-badge {
    display: inline-block;
    background-color: #375e9f;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: 700;
    margin-bottom: 1rem;

  }
  
  .work-card h3 {
    font-size: 1.5rem;
    line-height: 1.3;
    font-weight: 600;
    white-space: pre-line; /* '\n'을 적용하기 위함 */
  }


  
  .work-subtext {
    font-size: 0.875rem;
    color: #555;
    margin-top: 0.5rem;
  }
  
  .hero-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* 화면 전체 높이를 차지 */
    min-height: 100vh;
    padding: 0 4rem;
    background-size: cover;
    background-position: center;
    color: #fff;
  }

  .hero-section::before {
    content: "";
    position: absolute;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    pointer-events: none; /* 추가된 부분 */
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    flex: 1;
    max-width: 600px;
  }
  
  
  .hero-logo {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .hero-title {
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1rem;
  }
  
  .hero-subtext {
    font-size: 1.125rem;
    opacity: 0.85;
    margin-bottom: 2rem;
  }
  
  .hero-btn {
    padding: 1rem 2rem;
    font-size: 1.125rem;
    background: #375e9f;
    color: white;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    font-weight: 600;
    opacity: 0.87;
  }
  

  
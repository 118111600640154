/* 전체 컨테이너: 좌우 2개 구역을 가로로 나란히 배치 */
.split-container {
    display: flex;
    width: 100%;
    height: 60vh;
    margin: 0;
    padding: 0;
  }
  
  /* 좌측과 우측을 나누는 공통 스타일 */
  .split {
    position: relative;
    width: 50%;
    height: 100%;          /* <-- 이 줄 추가 (중요!) */
    overflow: hidden;
  }
  
  /* 개별 이미지에 대한 스타일 */
  .split-image {
    position: absolute;     /* <-- 이미지가 부모를 꽉 채우도록 */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
    opacity: 0.8;
  }
  
  /* 이미지 위에 표시될 텍스트 */
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
  }
  
  .left, .right {
    background-color: rgba(33, 31, 31, 0.7);
  }
  
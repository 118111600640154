/* 전체 영역 */
.stats-section {
    width: 100%;
    height: 120%;
    padding: 60px 20px;
    box-sizing: border-box;
    text-align: center;
    background-color: #fff; /* 필요에 따라 배경색 조정 */
  }
  
  /* 제목 스타일 */
  .stats-title {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 40px;
    white-space: pre-line; /* '\n'을 적용하기 위함 */
  }
  
  /* 통계 카드 컨테이너 (가로 배치) */
  .stats-container {
    display: flex;
    justify-content: center;
    gap: 100px; /* 카드 사이 간격 */
    flex-wrap: wrap; /* 화면 좁아지면 자동 줄바꿈 */
  }
  
  .highlight {
    color: #375e9f;  /* 원하는 색상으로 교체 */
    font-weight: 700; /* (선택) 굵게 */
  }
  
  /* 각 통계 카드 박스 */
  .stat-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px; /* 원하는 크기로 조정 */
    padding: 20px;
    border-radius: 12px;
    background-color: #f9f9f9;
  }
  
  /* 아이콘 스타일 */
  .stat-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
    object-fit: contain; /* 이미지 비율 유지 */
  }
  
  /* 텍스트 영역 묶음 */
  .stat-info {
    text-align: center;
  }
  
  /* 라벨, 값, 주석 스타일 */
  .stat-label {
    font-size: 1rem;
    font-weight: 600;
    margin: 12px;
  }
  
  .stat-value {
    font-size: 1.4rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 6px;
  }
  
  .stat-note {
    font-size: 0.85rem;
    color: #999;
  }
  
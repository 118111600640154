.application-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    min-height: 100vh;
    background: #f5f5f5;
  }
  
  .form-wrapper {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
  }
  
 .form-wrapper h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #375e9f;
  }
  
  .application-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  .fieldset-group {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 15px;
    margin-bottom: 20px;
  }
  
  .fieldset-group legend {
    font-weight: bold;
    padding: 0 10px;
    color: #375e9f;
  }
  
  .btn-submit {
    background: #375e9f;
    color: #fff;
    border: none;
    padding: 12px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .btn-submit:hover {
    background: #2e4b7d;
  }
  
  .btn-back {
    margin-top: 20px;
    background: #ccc;
    color: #333;
    border: none;
    padding: 12px;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    width: 100%;
  }
  
  .btn-back:hover {
    background: #b3b3b3;
  }
  